import React from 'react'
import useStyles from './suggestions-style'
import {
  Title,
  RecommendationProducts,
} from '../../../../ui'
import combineClassNames from '../../../../helpers/combineClassNames'

const SuggestionsView = ({ title, ...rest }) => {
  const styles = useStyles()
  return (
    <RecommendationProducts
      {...rest}
      className={combineClassNames([styles.container, rest.className])}
      ListHeaderComponent={(
        <Title
          className={styles.title}
          text={title}
        />
      )}
    />
  )
}

export default SuggestionsView
