import React from 'react'
import useStyles from './recently-viewed-style'
import Title from '../../../../ui/title-detail'
import RecentlyViewedProducts from '../../../../ui/recently-viewed-products'

const RecentlyViewedView = ({ title, ...rest }) => {
  const styles = useStyles()
  return (
    <div className={styles.container}>
      <RecentlyViewedProducts
        {...rest}
        ListHeaderComponent={(
          <Title
            className={styles.title}
            text={title}
          />
        )}
      />
    </div>
  )
}

export default RecentlyViewedView
