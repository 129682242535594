import { createUseStyles } from 'react-jss'
import hexToRgba from '../../../../helpers/hex-to-rgba'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    ':root': {
      '--swiper-theme-color': theme.colors.secondary,
      '--swiper-navigation-color': theme.colors.primary,
    },
  },
  '@keyframes loading': {
    from: {
      transform: 'translateX(-100%)',
    },
    to: {
      transform: 'translateX(100%)',
    },
  },
  loadingPlaceholder: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#eee',
    width: '100%',
    aspectRatio: 'var(--pages-product-image-aspect-ratio)',
    borderRadius: '1rem',
    userSelect: 'none',
    pointerEvent: 'none',
    '-webkit-touch-callout': 'none',
    '&::after': {
      content: '""',
      display: 'block',
      opacity: 0.25,
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(to right, ${hexToRgba(theme.colors.background, 0)} 0%,${hexToRgba(theme.colors.background, 1)} 50%,${hexToRgba(theme.colors.background, 0)} 100%)`,
      transform: 'translateX(-100%)',
      animationName: '$loading',
      animationDuration: '2s',
      animationTimingFunction: 'ease',
      animationIterationCount: 'infinite',
    },
  },
  productImage: {
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: 670,
    },
  },
  placeholder: {
    width: '100%',
    height: 0,
    paddingBottom: '117.5%',
    position: 'relative',
    backgroundColor: theme.colors.bgDark,
  },
  placeholderImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '40%',
  },
  mainWindow: {
    position: 'relative',
    width: '100%',
    // [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    //   maxWidth: '67rem',
    // },
  },
  scaleButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10,
    background: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
    '& img': {
      margin: 0,
      transform: 'scale(0.75) translate(10px, -10px)',
      [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
        transform: 'scale(1) translate(0,0)',
      },
    },
    display: 'var(--pages-product-image-zoom-button-display)',
  },
  slideInner: {
    aspectRatio: 'var(--pages-product-image-aspect-ratio)',
    overflow: 'hidden',
  },
  slideImage: {
    display: 'block',
    margin: 0,
  },
  paginationBullet: {
    '.swiper-pagination-horizontal.swiper-pagination-bullets &': {
      margin: '0 1rem',
    },
  },
  // imageZoom: ({ disableImageZoomMobile, disableImageZoomDesktop }) => ({
  //   display: !disableImageZoomMobile ? 'block' : 'none',
  //   width: '100%',
  //   [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
  //     display: !disableImageZoomDesktop ? 'block' : 'none',
  //   },
  // }),
  // imageWithoutZoom: ({ disableImageZoomMobile, disableImageZoomDesktop }) => ({
  //   display: disableImageZoomMobile ? 'block' : 'none',
  //   '& img': {
  //     display: disableImageZoomDesktop ? 'block' : 'none',
  //     margin: 0,
  //   },
  //   [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
  //     display: 'block',
  //   },
  // }),
  boxThumbs: {
    padding: '0 .2rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  thumb: {
    appearance: 'none',
    display: 'block',
    background: 'transparent',
    border: 'none',
    position: 'relative',
    padding: 0,
    marginTop: '1.6rem',
    maxWidth: '4rem',
    marginRight: '0.6rem',
    cursor: 'pointer',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      marginTop: `${theme.gap}rem`,
      maxWidth: '8rem',
      marginRight: '1.5rem',
    },
  },
  thumbSelected: {
    outline: '0.1rem solid #979797',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      outline: '0.1rem solid #979797',
      zIndex: 1,
      pointerEvents: 'none',
    },
  },
  thumbImage: {
    display: 'block',
    margin: 0,
  },
}))

export default useStyles
