import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  moreInfo: {
    textAlign: 'left',
    maxWidth: '77rem',
    marginTop: '3.2rem',
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginTop: `${theme.doubleGap}rem`,
    },
    '& article': {
      marginTop: '3.2rem',
      '& p': {
        fontSize: '1.4rem',
        marginBottom: 10,
        lineHeight: '2.4rem',
      },
    },
  },
  title: {
    margin: '0 0 1.2rem',
  },
  content: {
    fontSize: '1.4rem',

    '& h1': {
      fontSize: '3.6rem',
      lineHeight: 1.16,
      fontWeight: 600,
      textAlign: 'left',
      padding: '0 0 1.2rem',
      margin: '3rem 0 3rem',
      position: 'relative',
      color: theme.colors.primary,
      '&:after': {
        content: '" "',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '5rem',
        height: '0.3rem',
        backgroundColor: theme.colors.secondary,
      },
    },
    '& ol': {
      listStyleType: 'decimal',
      listStylePosition: 'outside',
      marginLeft: '1.5rem',
    },
    '& ul': {
      listStyleType: 'disc',
      listStylePosition: 'outside',
      marginLeft: '1.5rem',
    },
    '& li': {
      fontSize: '1.4rem',
      lineHeight: 1.71,
      marginBottom: 0,
    },
    '& p': {
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
    '& iframe, & p iframe': {
      height: 220,
      [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
        height: 430,
      },
    },
    '& a': {
      color: theme.colors.secondary,
      textDecoration: 'none',
    },
    '& img': {
      margin: [[30, 0]],
    },
    '& h2': {
      lineHeight: 1.18,
      fontSize: '3.4rem',
    },
    '& h3': {
      lineHeight: 1.18,
      fontSize: '2.8rem',
    },
    '& h4': {
      lineHeight: 1.18,
      fontSize: '2.4rem',
    },
    '& h5': {
      lineHeight: 1.18,
      fontSize: '2rem',
    },
    '& h6': {
      lineHeight: 1.18,
      fontSize: '1.8rem',
    },
  },
}))

export default useStyles
