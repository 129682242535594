import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  reviews: {
    width: 'auto',
    maxWidth: '100%',
    marginTop: `${theme.gap}rem`,
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginTop: `${theme.doubleGap}rem`,
      maxWidth: '51rem',
    },
  },
  title: {
    margin: '0 0 1.2rem',
  },
  hasMoreReviewsButton: {
    marginTop: 30,
    textAlign: 'left',
    fontSize: 14,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}))

export default useStyles
