/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React from 'react'
import useStyles from './reviews-style'
import { Title, ReviewCard, ReviewForm } from '../../../../ui'

const Reviews = ({
  t,
  reviews,
  user,
  onCreateReview,
  hasMoreReview,
  onNextReview,

}) => {
  const styles = useStyles()

  return (
    <div className={styles.reviews} id="reviews">
      <Title
        text={t('screens.product.reviews.title')}
        className={styles.title}
      />
      <div className={styles.usersReviewsStyle}>
        {
          _.map(reviews, (rev) => <ReviewCard key={`review-card-${rev.id}`} t={t} {...rev} />)
        }
      </div>
      {
        hasMoreReview && <p className={styles.hasMoreReviewsButton} onClick={() => onNextReview()}>{t('screens.product.reviews.viewMore')}</p>
      }
      <div>
        <ReviewForm t={t} onCreateReview={onCreateReview} user={user} />
      </div>
    </div>
  )
}

export default Reviews
